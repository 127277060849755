import './index.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { Tabs, Layout, Image, Row, Col } from 'antd';
import routes from '../../routes/index';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import Itadata from '../Language/Ita.js';

const { Footer } = Layout;

export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Foot extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
      displayWechat: 'none',
      displayOne: 'none',
      displayTwo: 'none',
      retractOne: '',
      retractTwo: '',
        }

    }

    componentWillReceiveProps = (nextProps) => {
    }
    
  changeWechat = () => {
    this.setState({
      displayWechat: 'block'
    })
  }
  onMouseOut = () => {
    this.setState({
      displayWechat: 'none',
      displayTwo: 'none',
      displayOne: 'none',
      retractTwo: '',
      retractOne: '',
    })
  }
  itemOne = () => {
    this.setState({
      displayOne: 'block',
      retractOne: ''
    })
  }
  itemTwo = () => {
    this.setState({
      displayTwo: 'block',
      retractTwo: ''
    })
  }
    render() {
      const {foot_info} = JSON.parse(window.localStorage.getItem('datas')) || Itadata
        return ( 
            <Footer style={{ backgroundColor: '#D3D3D3', padding: '0 0 24px 0' }}>
          <div className="footer-link">
            <Row justify='center' className="clearfix" style={{ height: '59px' }}>
              <Col xs={{span: 22}} sm={{span: 22}} md={{span: 22}} lg={{span: 22}} xl={{span: 22}} className="foot-wx">
                <span style={{fontSize:'16px'}}>Follow Filati famoso</span>
                <div className="foot-wx-icon">
                    <div className="wx-icon" onMouseOver={this.changeWechat} onMouseOut={this.onMouseOut} ></div>
                    {/* <Image src={} style={{
                      width: '70px',
                      display: this.state.displayWechat,
                      height: 'auto',
                    }}
                    /> */}
                  </div>
              </Col>
            </Row>
          </div>

          <Row justify='center' className="footer-law">
            <Col span={22}>
            <p>Filati famoso</p>
            <p>Copyright, Legal Notice, Privacy Policy, Technical Support</p>
            <p><a href="https://www.google.com/maps/place/Via+De+Amicis+Edmondo,+118,+25063+Gardone+Val+Trompia+BS,+%E6%84%8F%E5%A4%A7%E5%88%A9/@45.689924,10.1735098,17z/data=!3m1!4b1!4m6!3m5!1s0x47817c6dc58fe267:0xe313682b284603f2!8m2!3d45.6899203!4d10.1760847!16s%2Fg%2F11c5jxtqrz?entry=ttu" target="_blank" rel="noopener noreferrer">Via De Amicis Edmondo, 118/A, 25063 Gardone Val Trompia BS, Italy</a></p>
            <p>{foot_info.company1}</p>
            <p>{foot_info.company2}</p>
            <p><a href="https://www.google.com/maps/search/D3,+6%2FF.,+Por+Mee+Factory+Building,+500+Castle+Peak+Road,+Kowloon,+Hong+Kong/@22.3400049,114.1494882,17z/data=!3m1!4b1?entry=ttu" target="_blank" rel="noopener noreferrer">{foot_info.addr}</a></p>
            </Col>
          </Row>
        </Footer>
        )
    }
}


export default withNavigation(Foot);

const datas = {
    "items1": ['About Us','Products', 'Services', 'Contact Us'],
    "items2": ['yarn','machine', 'solution'],
    "aboutpage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1205079292.webp'),
            title: 'Filati Famoso Tessile',
            background: '#D3D3D3',
            describe: ['Founded in 2004 in Lombardy, Italy, is an Italian company specializing in the production of high-grade yarn and high-precision textile equipment. Since inception, we have been focusing on the production of high-quality products, with rich experience and expertise. After years of development and growth, the company has become leader in the industry.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: '',
            background: '#D3D3D3',
            describe: [' We have advanced modern equipment and a number of patented technologies in yarn production and manufacturing, Producing high-quality and high-performance products, which are widely used in high-end sectors such as branded clothing, home textiles, automotive interior and industrial production.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211336439738.webp'),
            title: '',
            background: '#D3D3D3',
            describe: ['Companies have an experienced, skilled R&D and production team, we focus on technical innovation and quality control, ensure that our products always keep ahead in the market. We are constant introduction of new technologies and processes to improve product quality and production efficiency.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211146067415.webp'),
            title: 'quality and credibility',
            background: '#FFFFFF',
            describe: ['Companies pay attention to product quality and credibility of the brand. At the same time, we also actively explore the international market, and established a partnership with international well-known brands and Italy Moschino brand, French Elle fashion, South Korea e-land Group and other global well-known enterprises have deep cooperation.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/fife-animation-2.png'),
            title: 'Industry professionals',
            background: '#FFFFFF',
            describe: ['The design capability, the printing know-how, the experience of the finishing process of the fabrics create a culture of Filatifamoso product as a mark of guarantee of quality without compromise.']
        }
    ],
    "productpage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41173806589.webp'),
            title: 'Texture Characteristics',
            background: '#EEEAE3',
            describe: ['Famous for its fine, exceptionally soft, and luxurious feel.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4188749126.webp'),
            title: 'Texture and Color',
            background: '#EEEAE3',
            describe: ['Exhibiting a fine, intricate texture and boasting a rich, vibrant color palette.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41153342447.webp'),
            title: 'Quality Certification',
            background: '#EEEAE3',
            describe: ['It has successfully met the rigorous standards of Oeko-Tex® standard 100 certification.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1200746331.webp'),
            title: 'Primary Application Fields',
            background: '#FFFFFF',
            describe: ['Primarily employed in the realms of high-end branded clothing, exquisite home textiles, automotive interior luxury, and industrial yarn production.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N935942270.webp'),
            title: 'Characteristics',
            background: '#FFFFFF',
            describe: ['Renowned for its uncompromising commitment to excellence in quality.']
        }
    ],
    "machinepage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41162959025.webp'),
            title: 'Intelligent management',
            background: '#EEEAE3',
            describe: ['Intelligent management enables automation of production processes and optimization for increased efficiency and resource utilization.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N994371410.webp'),
            title: 'Real-time remote monitoring',
            background: '#EEEAE3',
            describe: ['Real-time remote monitoring allows users to monitor production processes, check equipment status, and access production data from anywhere, facilitating informed decision-making.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211236651723.webp'),
            title: 'Fault warning',
            background: '#EEEAE3',
            describe: ['Fault warning system detects issues before they become critical, reducing production interruptions and maintenance costs.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41508511760.webp'),
            title: 'Cloud-connected secure production solutions',
            background: '#FFFFFF',
            describe: ['Cloud-connected solutions utilize cloud technology to enable seamless connectivity, secure data storage, and reliable data transmission, ensuring a safe production environment.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N960964128.webp'),
            title: 'Cloud "Internet of Things +"',
            background: '#FFFFFF',
            describe: ['The combination of cloud and IoT technology enables advanced production management, including data analytics, predictive maintenance, and automation control, driving digital transformation in manufacturing.']
        }
    ],
    "solutionpage": [
        {
            addr: '/one',
            img: require('../../assets/images/firstAnimation.jpg'),
            title: 'Cutting-edge IoT Cloud Tech',
            background: '#EEEAE3',
            describe: ['With the latest generation of Internet of Things (IoT) cloud technology, customers can benefit from cutting-edge advancements in cloud-connected IoT.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/identity-img.jpg'),
            title: 'Remote Monitoring',
            background: '#EEEAE3',
            describe: ["Remote monitoring allows customers to keep a constant, real-time watch over their machines' operations and production processes, granting them access from anywhere, at any time."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/thirdAnimation-1.jpg'),
            title: 'Data Access',
            background: '#EEEAE3',
            describe: ['Customers can effortlessly access critical production data and receive prompt fault alerts in order to make informed decisions.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/fourthAnimation.jpg'),
            title: 'Seamless Connectivity & Management',
            background: '#FFFFFF',
            describe: ['Seamless connectivity and management of production processes become a reality, thanks to the integration of cloud IoT technology.']
        }
    ],
    "servicespage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG211365103739.webp'),
            title: 'Wide Range of Options',
            background: '#EEEAE3',
            describe: ["Our selection is vast, offering a diverse array of fabrics and materials to meet a wide range of project needs. You'll find an extensive variety of colors, textures, and patterns, ensuring options for every creative vision."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG21400234969.webp'),
            title: 'Swift Delivery',
            background: '#EEEAE3',
            describe: ["Our delivery is swift and reliable, ensuring you receive the products you need on time to meet tight project schedules. Our extensive logistics network offers convenient delivery options."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: 'High-Quality Products',
            background: '#EEEAE3',
            describe: ['Our products are known for their exceptional quality, crafted from carefully selected premium materials. Rigorous quality control ensures that you receive materials of the highest standards, delivering both exquisite aesthetics and top-notch performance.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211321544779.webp'),
            title: 'Three Historical Collections',
            background: '#FFFFFF',
            describe: ['We take pride in offering three historically significant collections, each representing unique craftsmanship and design styles that cater to a variety of fashion and creative needs. These collections blend tradition with modernity, providing distinctive choices for your projects.']
        }
    ],
    "contactpage": {
        title: 'Contact us',
        li_title: 'We welcome you to contact us!',
        address: 'Via De Amicis Edmondo, 118/A, 25063 Gardone Val Trompia BS, Italy',
        phone: '+39 030 891 1364',
        addr_map: require('../../assets/images/mapita.png'),
    },
    "foot_info":{
        company1: 'Hong Kong Marketing Office',
        company2: 'FILATI FAMOSO TEXTILE HOLDINGS CO.,LIMITED',
        addr: 'D3, 6/F., Por Mee Factory Building, 500 Castle Peak Road, Kowloon, Hong Kong'
    }
}

export default datas;
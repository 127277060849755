import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Layout, Image } from 'antd';
import './0613.scss';
import de from '../../components/Language/gn';
import en from '../../components/Language/en';
import cn from '../../components/Language/cn';

const { Header, Content, Footer } = Layout;

const TargetComponent = () => {
    const { part } = de;
    const location = useLocation();
    const indexs = Number(location.search[1]);
    useEffect(() => {
        console.log(indexs); // 打印 indexs 的值
    }, [indexs, part]);
    const datas = part[2].items[indexs];
    return (
        <Layout style={{ margin: '0 auto 6vh', backgroundColor: '#EEEAE3' }}>
            <Row justify='center'>
                <Col className='module-content' xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }}>
                    <Row justify='center'>

                        <Col>
                            <Image src={datas.img.default} preview={false} />
                        </Col>
                    </Row>

                </Col>
                <Col className='news-detail' xs={{ span: 18 }} sm={{ span: 18 }} md={{ span: 13 }} lg={{ span: 13 }} xl={{ span: 13 }}>
                <div className="fadeUp">
                        <div className="title-en"></div>
                        <div className="title-cn">{datas.title}</div>
                    </div>
                    {datas.describe.map((items, indexe) => (
                        <p className='description' key={indexe}>{items}</p>
                    ))}
                </Col>
            </Row>

        </Layout>
    );
};

export default TargetComponent;

const datas = {
    "items1": ['關於我們','產品', '服務', '聯繫我們'],
    "items2": ['紗線','機器', '雲連接'],
    "aboutpage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1205079292.webp'),
            title: 'Filati Famoso Tessile',
            background: '#D3D3D3',
            describe: ['2004年成立於意大利倫巴第大區，是一家專業生產高檔紗線和高精度紡織設備的意大利公司。 自成立以來，我們一直專注於生產高品質的產品，擁有豐富的經驗和專業知識。 經過多年的發展壯大，公司已成為行業中的佼佼者。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: '',
            background: '#D3D3D3',
            describe: [' 我們在紗線生產製造方面擁有先進的現代化設備和多項專利技術，生產出高品質、高性能的產品，廣泛應用於品牌服裝、家紡、汽車內飾和工業生產等高端領域。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211336439738.webp'),
            title: '',
            background: '#D3D3D3',
            describe: ['公司擁有一支經驗豐富、技術精湛的研發和生產團隊，我們注重技術創新和質量控制，確保我們的產品始終在市場中保持領先。 我們不斷引進新技術、新工藝，以提高產品質量和生產效率。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211146067415.webp'),
            title: 'quality and credibility',
            background: '#FFFFFF',
            describe: ['公司注重產品質量和品牌信譽。 同時，我們還積極開拓國際市場，與國際知名品牌建立了合作夥伴關係，與意大利Moschino品牌、法國Elle時尚、韓國e-land集團等全球知名企業有著深度合作。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/fife-animation-2.png'),
            title: 'Industry professionals',
            background: '#FFFFFF',
            describe: ['面料的設計能力、印刷科技和精加工經驗創造了Filatifamoso產品的文化，作為品質保證的標誌']
        }
    ],
    "productpage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41173806589.webp'),
            title: '質地特點',
            background: '#EEEAE3',
            describe: ['以其細膩、异常柔軟和奢華的感覺而聞名。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4188749126.webp'),
            title: '質地和顏色',
            background: '#EEEAE3',
            describe: ['呈現出細膩、複雜的質地，並擁有豐富、充滿活力的調色板。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41153342447.webp'),
            title: '品質認證',
            background: '#EEEAE3',
            describe: ['它成功地達到了Oeko-Tex®標準100認證的嚴格標準。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1200746331.webp'),
            title: '主要應用領域',
            background: '#FFFFFF',
            describe: ['主要應用於高端品牌服裝、精緻家紡、汽車內飾奢侈品和工業紗線生產領域。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N935942270.webp'),
            title: '特點',
            background: '#FFFFFF',
            describe: ['卓越質量以及堅定承諾。']
        }
    ],
    "machinepage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG41162959025.webp'),
            title: '智慧管理',
            background: '#EEEAE3',
            describe: ['智慧管理實現了生產過程的自動化和優化，以提高效率和資源利用率。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N994371410.webp'),
            title: '實时遠程監控',
            background: '#EEEAE3',
            describe: ['實时遠程監控允許用戶從任何地方監控生產流程、檢查設備狀態和訪問生產數據，從而促進知情決策。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211236651723.webp'),
            title: '故障預警',
            background: '#EEEAE3',
            describe: ['故障警告系統在問題變得嚴重之前檢測問題，從而减少生產中斷和維護成本。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41508511760.webp'),
            title: '雲連接安全生產解決方案',
            background: '#FFFFFF',
            describe: ['雲連接解決方案利用雲科技實現無縫連接、安全的資料存儲和可靠的資料傳輸，確保安全的生產環境。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N960964128.webp'),
            title: '雲端“物聯網+”',
            background: '#FFFFFF',
            describe: ['雲和物聯網科技的結合實現了先進的生產管理，包括資料分析、預測性維護和自動化控制，推動了製造業的數位化轉型。']
        }
    ],
    "solutionpage": [
        {
            addr: '/one',
            img: require('../../assets/images/firstAnimation.jpg'),
            title: '前沿物聯網雲科技',
            background: '#EEEAE3',
            describe: ['憑藉最新一代物聯網（IoT）雲科技，客戶可以從雲連接物聯網的前沿進步中受益。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/identity-img.jpg'),
            title: '遙控監測',
            background: '#EEEAE3',
            describe: ["遠程監控使客戶能够對其機器的操作和生產流程進行持續、實时的監控，允許他們隨時隨地訪問。"]
        },
        {
            addr: '/one',
            img: require('../../assets/images/thirdAnimation-1.jpg'),
            title: '數據獲取',
            background: '#EEEAE3',
            describe: ['客戶可以毫不費力地訪問關鍵生產數據並收到及時的故障警報，以便做出明智的決策。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/fourthAnimation.jpg'),
            title: '無縫連接與管理',
            background: '#FFFFFF',
            describe: ['得益於雲物聯網科技的集成，生產流程的無縫連接和管理成為現實。']
        }
    ],
    "servicespage": [
        {
            addr: '/one',
            img: require('../../assets/images/VCG211365103739.webp'),
            title: '選擇多樣',
            background: '#EEEAE3',
            describe: ["我們的選擇範圍廣泛，提供各種各樣的面料和資料，以滿足廣泛的項目需求。 你會發現各種各樣的顏色、紋理和圖案，確保每個創意都有選擇。"]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG21400234969.webp'),
            title: '快速交付',
            background: '#EEEAE3',
            describe: ["我們的交付快速可靠，確保您按時收到所需的產品，以滿足緊張的專案進度。 我們廣泛的物流網絡提供了方便的配送選擇。"]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: '高品質產品',
            background: '#EEEAE3',
            describe: ['我們的產品以其卓越的質量而聞名，由精心挑選的優質資料製成。 嚴格的品質控制確保您獲得最高標準的資料，提供精緻的美學和一流的效能。']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211321544779.webp'),
            title: '三個歷史系列',
            background: '#FFFFFF',
            describe: ['我們自豪地提供了三個具有歷史意義的系列，每個系列都代表了獨特的工藝和設計風格，滿足了各種時尚和創意需求。 這些系列融合了傳統與現代，為您的項目提供獨特的選擇。']
        }
    ],
    "contactpage": {
        title: 'Contact us',
        li_title: 'We welcome you to contact us!',
        address: 'Via De Amicis Edmondo, 118/A, 25063 Gardone Val Trompia BS, Italy',
        phone: '+39 030 891 1364',
        addr_map: require('../../assets/images/mapita.png'),
    },
    "foot_info":{
        company1: '香港市場行銷公司',
        company2: '菲拉帝紡織控股有限公司',
        addr: '香港九龍荔枝角青山道500號百美工業大廈 6樓D3室'
    }
}

export default datas;
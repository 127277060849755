import { useNavigate, Outlet } from 'react-router-dom';
import React from 'react';
import { Carousel, Layout, Image, Row, Col, Card } from 'antd';
import Headertop from '../../components/Headertop';
import Linenav from '../../components/Linenav';
import Foot from '../../components/Foot';
import LinkWithAnimation from '../../components/Linkanimation';
import Itadata from '../../components/Language/Ita.js';
import './index.scss';
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Solution extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Products',
      isOpen: false,
      screenWidth: window.innerWidth
    }
  }

  componentDidMount() {
    document.body.classList.add('sidebar-closed');
  }
  componentDidUpdate() {
    const { isOpen } = this.state;
    const bodyClass = document.body.classList;
    if (isOpen) {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    } else {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    }
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      screenWidth: window.innerWidth
    })
  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getKeyrout = (e) => {
    this.setState({
      keyRout: e
    })
  }
  getOpen = (e) => {
    this.setState({
      isOpen: e
    })
  }
  getOpens = (e) => {
    const bodyClass = document.body.classList;
    if (e) {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    } else {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    }
  }
  leaveOut = (item, indexs) => {
    this.props.navigate(`${item.addr}?${indexs}`)
  }
  render() {
    const {solutionpage} = JSON.parse(window.localStorage.getItem('datas')) || Itadata
    const { screenWidth } = this.state;
    return (
      <div style={{ overflow: 'hidden' }}>

        <Row justify='center' className='all-items' >
          {
            solutionpage.map((item, indexs) => {
              return (

                <Col className='content-card' key={indexs} style={{ padding: '10px', overflow: 'hidden', backgroundColor: `${item.background}` }} span={24}>
                  {
                    screenWidth <= 768 ?
                      <Row justify='center'>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                          <h1>{item.title}</h1>
                        </Col>
                        <Col style={{textAlign:'center' }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                          <Image src={item.img.default} style={{ height: 'auto', marginBottom: '30px'}} preview={false} />
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                          <p>{item.describe.map(String)}</p>
                        </Col>
                      </Row>
                      :
                      (indexs % 2 == 0 ?
                        <Row justify='center'>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xxl={{ span: 17 }}>
                            <Row justify='center'>
                              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                                <h1>{item.title}</h1>
                                <p>{item.describe}</p>
                              </Col>
                              <Col className="image-col" style={{paddingLeft: '8%'}} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                                <Image className="zoom-image" src={item.img.default} style={{ height: 'auto', marginBottom: '30px' }} preview={false} />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        :
                        <Row justify='center' style={{paddingTop: `${indexs}` == 3 ? '90px' : '10px'}}>
                          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xxl={{ span: 17 }}>
                            <Row justify='center'>
                            <Col className="image-col" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                                <Image className="zoom-image" src={item.img.default} style={{ height: 'auto', marginBottom: '30px' }} preview={false} />
                              </Col>
                              <Col style={{paddingLeft: '8%'}} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                                <h1>{item.title}</h1>
                                <p>{item.describe}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>)
                  }

                </Col>
              )
            })
          }

          {/* <Col style={{ textAlign: 'center', padding: '10px' }} xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }} xxl={{ span: 11 }}>
          </Col> */}
        </Row>
        <Foot />
      </div>
    )
  }
}


export default withNavigation(Solution);

import { useNavigate, Outlet } from 'react-router-dom';
import React from 'react';
import { Carousel, Layout, Image, Row, Col, Card } from 'antd';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import Foot from '../../components/Foot';
import LinkWithAnimation from '../../components/Linkanimation';
import Itadata from '../../components/Language/Ita.js';
import './index.scss';
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const MyMapComponent = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 37.7749, lng: -122.4194 }} // 设置地图的默认中心位置
  >
    {/* 在这里添加地图上的其他组件和标记 */}
  </GoogleMap>
));
class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: 'Services',
      isOpen: false,
      screenWidth: window.innerWidth
    }
  }

  componentDidMount() {
    document.body.classList.add('sidebar-closed');
  }
  componentDidUpdate() {
    const { isOpen } = this.state;
    const bodyClass = document.body.classList;
    if (isOpen) {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    } else {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    }
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      screenWidth: window.innerWidth
    })
  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getKeyrout = (e) => {
    this.setState({
      keyRout: e
    })
  }
  getOpen = (e) => {
    this.setState({
      isOpen: e
    })
  }
  getOpens = (e) => {
    const bodyClass = document.body.classList;
    if (e) {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    } else {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    }
  }
  leaveOut = (item, indexs) => {
    this.props.navigate(`${item.addr}?${indexs}`)
  }
  render() {
    const { contactpage } = JSON.parse(window.localStorage.getItem('datas')) || Itadata
    return (
      <div style={{ overflow: 'hidden' }}>

        <Row justify='center' className='all-items' >
          <Col className='contact-back' xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }} xxl={{ span: 11 }}>
            <div class="t-col-form">
              <h1>Contact us</h1>
              <p>{contactpage.li_title}</p>
            </div>
            <div class="m-col-form">
              <div class="t-where">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.636" height="26" viewBox="0 0 21.636 26">
                  <g id="map-pin" transform="translate(-2)">
                    <path id="Tracciato_27" data-name="Tracciato 27" d="M22.636,10.818C22.636,18.455,12.818,25,12.818,25S3,18.455,3,10.818a9.818,9.818,0,0,1,19.636,0Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <circle id="Ellisse_17" data-name="Ellisse 17" cx="3.313" cy="3.313" r="3.313" transform="translate(9.403 7.364)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></circle>
                  </g>
                </svg>
                <span>Where we are:</span>
                <strong>Address</strong>
                <a href="https://www.google.com/maps/place/Via+De+Amicis+Edmondo,+118,+25063+Gardone+Val+Trompia+BS,+%E6%84%8F%E5%A4%A7%E5%88%A9/@45.689924,10.1735098,17z/data=!3m1!4b1!4m6!3m5!1s0x47817c6dc58fe267:0xe313682b284603f2!8m2!3d45.6899203!4d10.1760847!16s%2Fg%2F11c5jxtqrz?entry=ttu" target="_blank" rel="noopener noreferrer">{contactpage.address}</a>
              </div>
              <div class="t-phone">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.962" height="22.002" viewBox="0 0 21.962 22.002">
                  <path id="phone" d="M22.072,16.974v3.011a2.007,2.007,0,0,1-2.188,2.007,19.861,19.861,0,0,1-8.661-3.081A19.57,19.57,0,0,1,5.2,12.889a19.861,19.861,0,0,1-3.081-8.7A2.007,2.007,0,0,1,4.117,2H7.128A2.007,2.007,0,0,1,9.135,3.726a12.886,12.886,0,0,0,.7,2.82,2.007,2.007,0,0,1-.452,2.118L8.112,9.938a16.058,16.058,0,0,0,6.022,6.022l1.275-1.275a2.007,2.007,0,0,1,2.118-.452,12.886,12.886,0,0,0,2.82.7,2.007,2.007,0,0,1,1.726,2.037Z" transform="translate(-1.111 -1)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <span>Phone Number:</span>
                <span style={{fontSize:'20px'}}>{contactpage.phone}</span>
                
              </div>
            </div>
          </Col>
          <Col style={{ textAlign: 'center' }} xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 11 }} lg={{ span: 11 }} xl={{ span: 11 }} xxl={{ span: 11 }}>
            <div style={{paddingLeft:'3vw'}}>
            <Image src={contactpage.addr_map.default} style={{ height: 'auto'}} preview={true} />
            </div>
          </Col>
        </Row>
        {/* <div style={{ width: '100%', height: '400px' }}>
          <MyMapComponent
      containerElement={<div style={{ height: '100%' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
        </div> */}
        <Foot />
      </div>
    )
  }
}


export default withNavigation(Services);


import React from 'react';
//固定页面
import Home from '../pages/Home';
import New from '../pages/New';
import About from '../pages/About';
//主题页面
import Enbody from '../pages/Enbody';
import Machine from '../pages/Machine';
import Solution from '../pages/Solution';
import Cnbody from '../pages/Cnbody';
import Debody from '../pages/Debody';
import Contact from '../pages/Contact';
//下钻页面
import Dein from '../components/news/0613';
import Cnin from '../components/news/cnin';
import Enin from '../components/news/enin';
let routes = [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "",
        element: <Debody />,
      },
      {
        path: "one",
        element: <Dein />,
      },

    ]
  },
  {
    path: "products",
    element: <About />,
    children: [
      {
        path: "",
        element: <Enbody />,
      },
      {
        path: "yarn",
        element: <Enbody />,
      },
      {
        path: "machine",
        element: <Machine />,
      },
      {
        path: "solution",
        element: <Solution />,
      },

    ]
  },
  {
    path: "services",
    element: <New />,
    children: [
      {
        path: "",
        element: <Cnbody />,
      },
      {
        path: "one",
        element: <Cnin />,
      },

    ]
  },
  {
    path: "contact",
    element: <New />,
    children: [
      {
        path: "",
        element: <Contact />,
      },
      {
        path: "one",
        element: <Cnin />,
      },

    ]
  }
];

export default routes;
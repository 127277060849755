import { useNavigate, Outlet } from 'react-router-dom';
import React from 'react';
import { Carousel, Layout, Image, Row, Col, Card } from 'antd';
import Headertop from '../../components/Headertop';
import Linenav from '../../components/Linenav';
import Foot from '../../components/Foot';
import Debody from '../Debody';
import Itadata from '../../components/Language/Ita.js';
import './index.scss';
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '首页',
      code: ['首页', '关于我们', '新闻中心', '业务介绍', '投资关系', '联系我们'],
      company: '大信智谷',
      companyInfo: '大信智谷是由富轹科技集团与大信科融、中信、中国电子及阿里云等大型集团的旗下机构联合打造，提供产业导入及投资、园区开发及运营等全链条服务的、具有优秀资源整合能力的工业地产综合平台',
      isOpen: false,
      datas:Itadata
    }
  }

  componentDidMount() {
    document.body.classList.add('sidebar-closed');
  }
  componentDidUpdate() {
    const { isOpen } = this.state;
    const bodyClass = document.body.classList;
    if (isOpen) {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    } else {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    }
  }
  componentWillReceiveProps(nextProps) {

  }
  getRouteitems = (e) => {
    this.setState({
      routeitems: e
    })
  }
  getDatas = (e) => {
    this.setState({
      datas: e
    })
  }
  getOpen = (e) => {
    this.setState({
      isOpen: e
    })
  }
  getOpens = (e) => {
    const bodyClass = document.body.classList;
    if (e) {
      bodyClass.remove('sidebar-open');
      bodyClass.add('sidebar-closed');
    } else {
      bodyClass.remove('sidebar-closed');
      bodyClass.add('sidebar-open');
    }
  }
  leaveOut=(item,indexs)=>{
    this.props.navigate(`${item.addr}?${indexs}`)
  }
  render() {
    return (

      <Layout className='main-container'>
        <div className="sidebar">
          <Linenav getOpen={this.getOpens} />
        </div>
        <div className="page-content">
          <div className="page-contents">
            <Headertop getOpen={this.getOpen} getAlldata={this.getDatas} />
          </div>
          {/* <Outlet datas= {this.state.datas}/> */}
          <Debody datas= {this.state.datas}/>
        </div>
      </Layout>
    )
  }
}


export default withNavigation(Home);

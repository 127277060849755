const datas = {
    cn: { top_title: 'ENGLISH' },
    company: 'Schiegabor Mechanikermeister GmbH',
    addr: 'Botnanger Str. 41, 70193 Stuttgart, Deutschland',
    tel: '+49 800 7237019235',
    part: [
        {
            co_title: 'Our Company',
            description: ['●Since it was founded in 1980, Schiegabor GmbH has established itself as one of the internationally most recognised specialists for Laser Equipment. The company has achieved solid growth, while continuously maintaining high quality standards. Our past and present success has proved us right. This is how Schiegabor GmbH grew from a two-man workshop in Willich, Viersen, in North Rhine-Westphalia, to a globally operating company based in Kräherwald, west of Stuttgart. And still, during all the changes we have seen since the company was founded, we have always maintained our philosophy and our high standards, always aiming for the best possible result.', '●Our team of more than 100 employees works with state-of-the-art equipment on more than 7,000 square meters. The team is a healthy mix of experienced specialists with many years of experience and highly qualified young talents. In order to ensure that we are continuing to keep this expertise in the company and pass it on to the next generation, Schiegabor has its own trainee programme.'],
            img: require('../../images/un_un.jpg'),
            items: []
        },
        {
            co_title: 'Vision',
            description: ['●As an supplier for mechatronic systems, we meet your needs and specific tasks with the contribution of our many years of experience.  Starting with the solution concept through to series production.  Schiegabor GmbH is your competent partner when it comes to System integration in the field of power electronics, both in Industrial as well as in the automation sector.'],
            img: require('../../images/vision.jpg'),
            items: []
        },
        {
            co_title: 'Products and technologies',
            description: ['●On the way to “Next Generation Productivity', '●With a broad and unique product portfolio, the company offers system solutions that allow production line to achieve precision and efficiency.  ​This makes manufacturing more precise, more efficient, and more productive.  Through intensive research, Schiegabor is developing innovative technology in four future fields. The connecting element is digitization.'],
            img: require('../../images/product.jpg'),
            items: [
                {
                    addr: '/en/one',
                    img: require('../../images/product01.jpg'),
                    title: 'COMPUTER NUMERICAL CONTROL MACHINE TOOLS',
                    describe: ['●For a long time, workpieces were created by the direct and freehand use of a tool by people. This type of production was replaced by machine tools, i.e. machines that guided the tool path and thus set new standards in precision and efficiency.   Schiegabor numerical controls (NC) began when the concepts of a programmable logic for tool guidance were first introduced in the automation of such machine tools. With an increasing market demand in the second half of the last century, Schiegabor NC technology also experienced a sustained growth spurt – until it was replaced by Schiegabor NC technology and today it is impossible to imagine the world of manufacturing without it.']
                },
                {
                    addr: '/en/one',
                    img: require('../../images/product02.jpg'),
                    title: 'SERVOMOTOR AND DRIVE',
                    describe: ['●Schiegabor has an extensive portfolio of semiconductors for the design of servo motors in the power range from several hundred watts to several hundred kilowatts. Our products are particularly suitable for use in the fields of robotics, handling and machine tools. But other applications also benefit from the high reliability, the longer service life, the maximum positioning accuracy and the fast response without overshoot.']
                },
                {
                    addr: '/en/one',
                    img: require('../../images/product03.jpg'),
                    title: 'INTELLIGENT LASER DEVICES',
                    describe: ['●At Schiegabor, we strive to push the boundaries of laser technology to provide innovative and intelligent solutions for industrial applications. We strive to deliver cutting-edge solutions that help industries around the world achieve new levels of precision, efficiency and productivity.']
                },
                {
                    addr: '/en/one',
                    img: require('../../images/product04.jpg'),
                    title: 'CRANE MOTORS',
                    describe: ['●Schiegabor crane motors for the heaviest lifting applications and up to 600 starts per hour.', '●Crane motors or drives for lifting applications must be dimensioned for short-term or intermittent operation.  Schiegabor crane motors are ideally equipped for all lifting applications. They are designed for up to 600 starts per hour and are particularly low-maintenance.', '●In addition, our crane motors are particularly stable and robust. This ensures that your crane systems and hoists operate smoothly and reliably, even in the event of mechanical shock loads, possible vibrations or overloads.']
                },
                {
                    addr: '/en/one',
                    img: require('../../images/product05.jpg'),
                    title: 'CUSTOMIZED PRIVATE ELEVATOR',
                    describe: ['●Schiegabor produces three different home lifts: Schiegabor HomeLift, Schiegabor HomeLift Compact and Schiegabor HomeLift Access. Browse through our models to find the designs, finishes and models that best suit your needs!']
                },
                {
                    addr: '/en/one',
                    img: require('../../images/product06.jpg'),
                    title: '',
                    describe: ['']
                },
            ]
        },
        {
            co_title: 'Contact Us',
            description: ['Experience the future of machine tools construction with us.   Contact us today to learn more about our innovative solutions and how we can take your manufacturing capabilities to a new level. Let us be your trusted partner on the road to success.'],
            img: require('../../images/content.jpg'),
            items: []
        },

    ]
}
export default datas;
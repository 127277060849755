import React from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Itadata from '../Language/Ita.js';
import { NavLink as Link } from 'react-router-dom';
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
};
const {items1} = JSON.parse(window.localStorage.getItem('datas')) || Itadata;
const {items2} = JSON.parse(window.localStorage.getItem('datas')) || Itadata;

const items = [{
  key: "/about",
  label: <Link to="/about">{items1[0]}</Link>
}, {
  key: "/products",
  label: <Link to="/products">{items1[1]}</Link>,
  children: [{
    key: "/products/yarn",
    label: <Link to="/products/yarn">{items2[0]}</Link>
  }, {
    key: "/products/machine",
    label: <Link to="/products/machine">{items2[1]}</Link>
  },
    {
      key: "/products/solution",
      label: <Link to="/products/solution">{items2[2]}</Link>
    }, 
  ]
}, {
  key: "/services",
  label: <Link to="/services">{items1[2]}</Link>,
}
, {
  key: "/contact",
  label: <Link to="/contact">{items1[3]}</Link>,
}]

class Linenav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  onClick = () => {
    this.props.getOpen(true)
  };
  render() {
    return (
      <Menu
        onClick={this.onClick}
        style={{
          width: '100%',
          fontSize: '15px',
          backgroundColor: '#F9F7F3'
        }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
      />
    )
  }
}
export default Linenav;
const datas = {
    cn: { top_title: '施嘉伯中國' },
    company: 'Schiegabor Mechanikermeister GmbH',
    addr: 'Botnanger Str. 41, 70193 Stuttgart, Deutschland',
    tel: '+49 800 7237019235',
    part: [
        {
            co_title: '關於我們',
            description: ['●自1980年成立以來，Schiegabor GmbH 已成為國際上最受認可的激光設備專家之一。公司實現了穩健增長，同時不斷保持高質量標準，過去和現在持續見證我們的成功。Schiegabor GmbH從位於北萊茵-威斯特法倫州菲爾森維利希的兩人車間發展成為一家總部位於斯圖加特西部科拉赫瓦爾德的全球性公司。自公司成立以來，我們雖然歷經各種坎坷，但我們始終保持自身的理念和高標準，始終以最優結果為導向。公司佔地7000多平方米，擁有最先進的設備，100多名員工。我們的團隊由具有豐富經驗的專家和高素質的年輕人才組成，並且為了確保我們專業知識合計數的傳承，Schiegabor 制定了自己的培訓生計劃。'],
            img: require('../../images/un_un.jpg'),
            items: []
        },
        {
            co_title: '願景',
            description: ['●作為機電一體化系統的供應商，我們憑藉多年的經驗滿足您的需求和特定任務。從解決方案概念開始一直到系列生產。在工業和自動化領域、電力電子領域的係統集成方面，Schiegabor GmbH 是您的得力合作夥伴。'],
            img: require('../../images/vision.jpg'),
            items: []
        },
        {
            co_title: '產品和技術',
            description: ['●施嘉伯以數字化為連接要素，持續耕耘於“新一代生產力”之路，通過豐富和獨特的產品組合，提高生產線的精度和效率，進而使得製造更加精確、更加高效、生產力更高。'],
            img: require('../../images/product.jpg'),
            items: [
                {
                    addr: '/cn/one',
                    img: require('../../images/product01.jpg'),
                    title: '電腦數控機床',
                    describe: ['●長期以來，工件是由人們直接徒手使用工具來製造的。工件是由人们直接徒手使用工具来制造的。這種類型的生產被機床所取代，即引導刀具路徑的機器，從而在精度和效率方面樹立了新的標準。Schiegabor 數控 (NC) 始於用於道具引導的可編程邏輯概念首次引入此類機床的自動化中。隨著上世紀下半葉市場需求的不斷增加，Schiegabor NC技術也經歷了持續的井噴式增長——直到它被Schiegabor NC技術取代，而今天我們無法想象沒有它的製造業世界。']
                },
                {
                    addr: '/cn/one',
                    img: require('../../images/product02.jpg'),
                    title: '伺服電機及驅動',
                    describe: ['●Schiegabor 擁有廣泛的半導體產品組合，用於設計功率範圍從幾百瓦到幾百千瓦的伺服電機。 我們的產品特別適用於機器人、搬運和機床領域。但其他應用也受益於高可靠性、更長的使用壽命、最大的定位精度和無超調的快速響應。']
                },
                {
                    addr: '/cn/one',
                    img: require('../../images/product03.jpg'),
                    title: '智能激光設備',
                    describe: ['●在 Schiegabor，我們努力突破激光技術的界限，為工業應用提供創新和智能的解決方案。我们致力於提供尖端的解決方案，幫助世界各地的行業達到新的精度、效率和生產力水平。']
                },
                {
                    addr: '/cn/one',
                    img: require('../../images/product04.jpg'),
                    title: '起重機電機',
                    describe: ['●Schiegabor 起重機電機適用於最重的起重應用，每小時啟動次數高達600次。', '●用於起重應用的的起重機電機或驅動器的尺寸必須適合短期或間歇操作。Schiegabor 起重機電機非常適合所有起重應用。它們設計為每小時最多啟動600次，並且維護成本特別低。', '●此外，我們的起重機電機特別穩定且堅固。這可以確保您的起重機系統和起重機平穩可靠地運行，即使在機械衝擊載荷、可能的振動或過載的情況下也是如此。']
                },
                {
                    addr: '/cn/one',
                    img: require('../../images/product05.jpg'),
                    title: '定制別墅電梯',
                    describe: ['●Schiegabor 生產三種不同的家用電梯：Schiegabor HomeLift、Schiegabor HomeLift Compact 和 Schiegabor HomeLift Access。 瀏覽我們的產品，找到最適合您需求的設計、裝潢和型號！']
                },
                {
                    addr: '/cn/one',
                    img: require('../../images/product06.jpg'),
                    title: '數字智慧運營',
                    describe: ['']
                },
            ]
        },
        {
            co_title: '聯繫我們',
            description: ['與我們一起體驗機床製造的未來。請立即聯繫我們，詳細了解我們的創新解決方案，以及我們如何將您的製造能力提升到新的水平。讓我們成為您成功道路上值得信賴的合作夥伴。'],
            img: require('../../images/content.jpg'),
            items: []
        },

    ]
}

export default datas;
const datas ={
    "items1": ['Chi siamo','Prodotti', 'Servizi', 'Contattaci'],
    "items2": ['filati','macchina', 'soluzione'],
    "aboutpage":[
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1205079292.webp'),
            title: 'Filati Famoso Tessile',
            background:'#D3D3D3',
            describe: ["Fondata nel 2004 in Lombardia, Italia, è un'azienda italiana specializzata nella produzione di filati di alta qualità e attrezzature tessili di alta precisione. Fin dall'inizio, ci siamo concentrati sulla produzione di prodotti di alta qualità, con una ricca esperienza e competenza. Dopo anni di sviluppo e crescita, l'azienda è diventata leader nel settore."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: '',
            background:'#D3D3D3',
            describe: ['Disponiamo di attrezzature moderne avanzate e di una serie di tecnologie brevettate nella produzione e produzione di filati, producendo prodotti di alta qualità e ad alte prestazioni, che sono ampiamente utilizzati in settori di fascia alta come abbigliamento di marca, tessuti per la casa, interni automobilistici e produzione industriale.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211336439738.webp'),
            title: '',
            background:'#D3D3D3',
            describe: ["Le aziende hanno un team di ricerca e sviluppo esperto e qualificato, ci concentriamo sull'innovazione tecnica e sul controllo di qualità, assicurano che i nostri prodotti rimangano sempre avanti nel mercato. Stiamo introducendo costantemente nuove tecnologie e processi per migliorare la qualità del prodotto e l'efficienza produttiva."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211146067415.webp'),
            title: 'qualità e credibilità',
            background:'#FFFFFF',
            describe: ["Le aziende prestano attenzione alla qualità del prodotto e alla credibilità del marchio. Allo stesso tempo, esploriamo attivamente anche il mercato internazionale e stabiliamo una partnership con marchi internazionali ben noti e il marchio Italia Moschino, la moda francese Elle, il gruppo e-land della Corea del Sud e altre imprese globali ben note hanno una profonda cooperazione."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/fife-animation-2.png'),
            title: 'Professionisti del settore',
            background:'#FFFFFF',
            describe: ["La capacità progettuale, il know-how di stampa, l'esperienza del processo di finitura dei tessuti creano una cultura del prodotto Filatifamoso come marchio di garanzia di qualità senza compromessi."]
        }
    ],
    "productpage":[
        {
            addr: '/one',
            img: require('../../assets/images/VCG41173806589.webp'),
            title: 'Caratteristiche della trama',
            background:'#EEEAE3',
            describe: ['Famosa per la sua sensazione fine, eccezionalmente morbida e lussuosa.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4188749126.webp'),
            title: 'Trama e colore',
            background:'#EEEAE3',
            describe: ['Mostra una texture fine e intricata e vanta una ricca e vibrante tavolozza di colori.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41153342447.webp'),
            title: 'Certificazione di qualità',
            background:'#EEEAE3',
            describe: ['Ha soddisfatto con successo i rigorosi standard della certificazione Oeko-Tex® standard 100.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N1200746331.webp'),
            title: 'Principali campi di applicazione',
            background:'#FFFFFF',
            describe: ["Utilizzato principalmente nei regni dell'abbigliamento di marca di alta gamma, tessuti per la casa squisiti, lusso automobilistico interno e produzione di filati industriali."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N935942270.webp'),
            title: 'Caratteristiche',
            background:'#FFFFFF',
            describe: ["Rinomata per il suo impegno senza compromessi per l'eccellenza nella qualità."]
        }
    ],
    "machinepage":[
        {
            addr: '/one',
            img: require('../../assets/images/VCG41162959025.webp'),
            title: 'Gestione intelligente',
            background:'#EEEAE3',
            describe: ["La gestione intelligente consente l'automazione dei processi produttivi e l'ottimizzazione per aumentare l'efficienza e l'utilizzo delle risorse."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N994371410.webp'),
            title: 'Monitoraggio remoto in tempo reale',
            background:'#EEEAE3',
            describe: ['Il monitoraggio remoto in tempo reale consente agli utenti di monitorare i processi produttivi, verificare lo stato degli apparecchi e accedere ai dati di produzione da qualsiasi luogo, facilitando la presa di decisioni informate.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211236651723.webp'),
            title: 'Avviso di guasto',
            background:'#EEEAE3',
            describe: ['Il sistema di avviso di guasto rileva i problemi prima che diventino critici, riducendo le interruzioni della produzione e i costi di manutenzione.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41508511760.webp'),
            title: 'Soluzioni di produzione sicure connesse al cloud',
            background:'#FFFFFF',
            describe: ['Le soluzioni connesse al cloud utilizzano la tecnologia cloud per consentire una connettività senza soluzione di continuità, una sicura conservazione dei dati e una trasmissione affidabile dei dati, garantendo un ambiente di produzione sicuro.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG41N960964128.webp'),
            title: '"Internet delle cose +" del cloud',
            background:'#FFFFFF',
            describe: ['La combinazione di tecnologia cloud e IoT consente una gestione avanzata della produzione, tra cui analisi dei dati, manutenzione predittiva e controllo automatizzato, spingendo la trasformazione digitale nella produzione.']
        }
    ],
    "solutionpage":[
        {
            addr: '/one',
            img: require('../../assets/images/firstAnimation.jpg'),
            title: "Tecnologia Cloud IoT all'avanguardia",
            background:'#EEEAE3',
            describe: ["Con l'ultima generazione della tecnologia cloud Internet of Things (IoT), i clienti possono godere dei più recenti progressi nella connessione cloud delle cose."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/identity-img.jpg'),
            title: 'Monitoraggio Remoto',
            background:'#EEEAE3',
            describe: ["Il monitoraggio remoto consente ai clienti di tenere costantemente d'occhio le operazioni delle loro macchine e i processi di produzione, garantendo loro l'accesso da qualsiasi luogo e in qualsiasi momento."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/thirdAnimation-1.jpg'),
            title: 'Accesso ai Dati',
            background:'#EEEAE3',
            describe: ['I clienti possono accedere facilmente ai dati cruciali di produzione e ricevere tempestivi avvisi di guasto per prendere decisioni informate.']
        },
        {
            addr: '/one',
            img: require('../../assets/images/fourthAnimation.jpg'),
            title: 'Connessione e Gestione Senza Interruzioni',
            background:'#FFFFFF',
            describe: ["La connettività senza soluzione di continuità e la gestione dei processi produttivi diventano realtà grazie all'integrazione della tecnologia cloud IoT."]
        }
    ],
    "servicespage":[
        {
            addr: '/one',
            img: require('../../assets/images/VCG211365103739.webp'),
            title: 'Vasta Scelta',
            background:'#EEEAE3',
            describe: ["La nostra selezione è vasta, offrendo una varietà di tessuti e materiali per soddisfare una vasta gamma di esigenze di progetto. Troverai un'ampia varietà di colori, texture e motivi, garantendo opzioni per ogni visione creativa."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG21400234969.webp'),
            title: 'Consegna Rapida',
            background:'#EEEAE3',
            describe: ["La nostra consegna è rapida e affidabile, garantendo che tu riceva i prodotti di cui hai bisogno in tempo per rispettare le scadenze dei tuoi progetti. La nostra estesa rete logistica offre opzioni di consegna comode."]
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG4112545.webp'),
            title: 'Prodotti di Alta Qualità',
            background:'#EEEAE3',
            describe: ['I nostri prodotti sono noti per la loro eccezionale qualità, realizzati con materiali premium accuratamente selezionati. Un rigoroso controllo di qualità garantisce che ricevi materiali dei massimi standard, offrendo estetica squisita e prestazioni di alto livello..']
        },
        {
            addr: '/one',
            img: require('../../assets/images/VCG211321544779.webp'),
            title: 'Tre Collezioni Storiche',
            background:'#FFFFFF',
            describe: ['Siamo orgogliosi di offrire tre collezioni storicamente significative, ognuna delle quali rappresenta un artigianato e uno stile di design unici che soddisfano una varietà di esigenze di moda e creatività. Queste collezioni fondono tradizione e modernità, offrendo scelte distinte per i tuoi progetti.']
        }
    ],
    "contactpage": {
        title: 'Contact us',
        li_title: 'We welcome you to contact us!',
        address: 'Via De Amicis Edmondo, 118/A, 25063 Gardone Val Trompia BS, Italy',
        phone: '+39 030 891 1364',
        addr_map: require('../../assets/images/mapita.png'),
    },
    "foot_info":{
        company1: 'Ufficio marketing di Hong Kong',
        company2: 'FILATI FAMOSO TEXTILE HOLDINGS CO.,LIMITED',
        addr: 'D3, 6/F., Por Mee Factory Building, 500 Castle Peak Road, Kowloon, Hong Kong'
    }
}
    
export default datas;
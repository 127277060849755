import './index.scss';
import { useNavigate } from 'react-router-dom';
import { NavLink as Link } from 'react-router-dom';
import React from 'react';
import {
  CloseOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Layout, Menu, Image, Button, Row, Col, Dropdown } from 'antd';
import routes from '../../routes/index';
import logo from '../../images/yarn_logo.png'
import gndata from '../Language/gn.js';
import chdata from '../Language/ch.js';
import itadata from '../Language/Ita';
const { Header } = Layout;


export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const {items1} = JSON.parse(window.localStorage.getItem('datas')) || itadata
const {items2} = JSON.parse(window.localStorage.getItem('datas')) || itadata

class Headertop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      isOpen: false,
      mode: window.innerWidth < 768 ? 'vertical' : 'horizontal',
      selectId: '2',
      routeItems: [],
      alldata: itadata,
      language:window.localStorage.getItem('language')||'en',
      items: [{
        key: "/about",
        label: <Link to="/about">{items1[0]}</Link>
      }, {
        key: "/products",
        label: <Link to="/products">{items1[1]}</Link>,
        children: [{
          key: "/products/yarn",
          label: <Link to="/products/yarn">{items2[0]}</Link>
        }, {
          key: "/products/machine",
          label: <Link to="/products/machine">{items2[1]}</Link>
        },
          {
            key: "/products/solution",
            label: <Link to="/products/solution">{items2[2]}</Link>
          }, 
        ]
      }, {
        key: "/services",
        label: <Link to="/services">{items1[2]}</Link>,
      }
      , {
        key: "/contact",
        label: <Link to="/contact">{items1[3]}</Link>,
      }]
    }
  }
  handleResize = () => {
    this.setState({
      mode: window.innerWidth < 768 ? 'vertical' : 'horizontal',
    });
  };
  componentDidMount = () => {
    
    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  componentWillReceiveProps(nextProps) {

  }
  changeRoute = (e) => {
    const routItem = e.keyPath[e.keyPath.length - 1];
    let routeItems = [];
    const { items } = this.state
    items.forEach(item => {
      if (item.key == routItem) {
        routeItems.push(item)
      }
    })
    // this.props.getKeyrout(e.key)
    // this.props.getRouteitems(routeItems[0])
    this.setState({
      selectId: e.key,
      routeItems
    })
  }
  toggleCollapsed = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };
  closeNav = () => {
    this.props.getOpen(false)
    this.setState({
      isOpen: false
    })
  };
  openNav = () => {
    this.props.getOpen(true)
    this.setState({
      isOpen: true
    })
  };
  goHome = () => {
    this.props.navigate('/home')
  };
  langChan = (e) => {
    window.localStorage.setItem('language', e.key);
    window.location.reload();
    if (e.key == 'en') {
      window.localStorage.setItem('datas', JSON.stringify(gndata));
      this.props.getAlldata(gndata)
    } else if (e.key == 'cn') {
      window.localStorage.setItem('datas', JSON.stringify(chdata));
      this.props.getAlldata(chdata)
    }else{
      window.localStorage.setItem('datas', JSON.stringify(itadata));
      this.props.getAlldata(itadata)
    }
    this.setState({
      language:e.key
    })
      
    
  };
  render() {
    const { isOpen, items, collapsed } = this.state;
    
    const menu = (
      <Menu onClick={this.langChan}>
        <Menu.Item key="ita">ita</Menu.Item>
        <Menu.Item key="en">en</Menu.Item>
        <Menu.Item key="cn">cn</Menu.Item>
      </Menu>
    );
    return (
      <div className='header' style={{ top: 0, zIndex: 1, width: '100%' }}>
        <div className='nav-switch'>
          <CloseOutlined style={{ display: isOpen == true ? 'block' : 'none' }} className='nav-close' onClick={this.closeNav} />
          <MenuOutlined style={{ display: isOpen == false ? 'block' : 'none' }} className='nav-open' onClick={this.openNav} />
        </div>
        <Row justify="center" className='headermid'>

          <Col style={{textAlign:'center', padding:'10px'}} xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 2 }} lg={{ span: 3 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
            <Image className='logo' src={logo} style={{ width: 163, height: 'auto' }} preview={false} onClick={this.goHome} />
          </Col>
          <Col className='upmenu' xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 20 }} lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 14 }}>
            <Menu
              className='menutitle nav'
              theme="light"
              mode={this.state.mode}
              // selectedKeys={['/about']}
              onSelect={this.changeRoute}
              items={items}
            >
            </Menu>
          </Col>
        </Row>
        <Dropdown className="lang-chan" overlay={menu} trigger={['click']}>
        <Button  onClick={(e) => e.preventDefault()}>
          {this.state.language}
          {/* <DownOutlined /> */}
        </Button>
      </Dropdown>
      </div>
    )
  }
}


export default withNavigation(Headertop);
